import React from 'react';
import Googlemap from '../components/Googlemap';

const Map = () => {
  return (
    <div>
      <div className='header-custom'>
      </div>
      <div className='googlemap'>
        <Googlemap />
      </div>
    </div>
  );
};

export default Map;
