import React from 'react';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-wrap'>
        &copy; 2025 - Mariage de Leslie & Loïc. All rights reserved.
        <a href="#top" id="btt">Top <i className="fa fa-chevron-up"></i></a>
      </div>
    </footer>
  );
};

export default Footer;
