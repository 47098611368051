import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import mariage_1 from '../images/91710636-939f-4e87-8c0f-6480786b030d.JPG';
import mariage_2 from '../images/e32575f9-455d-4ce2-8146-745c21afa2d7.JPG';
import mariage_3 from '../images/f5903efc-ebde-484f-8a98-9b1e4f8144f0.JPG';
import mariage_4 from '../images/3166827a-7a44-4511-99da-badaa319238b.JPG';
import mariage_5 from '../images/8f09c8e8-ebc8-4fa9-9499-b08ec9e8396d.JPG';
import './simplecarousel.css'

const SimpleCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className='simplecarousel'>
      <Slider {...settings}>
        <div>
            <img src={mariage_1} alt="mariage-1" />
        </div>
        <div>
            <img src={mariage_2} alt="mariage-2" />
        </div>
        <div>
            <img src={mariage_3} alt="mariage-3" />
        </div>
        <div>
            <img src={mariage_4} alt="mariage-4" />
        </div>
        <div>
            <img src={mariage_5} alt="mariage-5" />
        </div>
      </Slider>
    </div>
  );
};

export default SimpleCarousel;
