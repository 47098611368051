import React from 'react';
import { Link } from 'react-router-dom';
import image from '../images/a347ce99-9901-4361-953e-f3306909469c.JPG';
import SimpleCarousel from '../components/SimpleCarousel';

const HomePage = () => {
  const heroStyle = {
    backgroundImage: `url(${image})`,
    textAlign: 'right'
  };
  const aStyle = {
    color: '#FFF'
  };
  return (
    <div>
      <div className="hero" style={heroStyle}>
        <div className="hero-inner">
          <div className="inner">
            <h1><span className="border border-top border-bottom">Mariage de Leslie & Loïc</span></h1>
            <div className="hero-line-one"></div>
            <div className="hero-line-two">
              <span className="border border-bottom">
                <Link to="/" style={aStyle}>le 14 Juin 2025</Link>
              </span>
            </div>
          </div>
          <Link className="more-arrow" to="#content">
            <i className="fa fa-chevron-down"></i>
            <span>Scroll Down</span>
          </Link>
        </div>
      </div>
      <SimpleCarousel />
      <div className="wrap full-wrap">
        <div className="main-wrap">
          <section className="main main-archive">
            <div className="loop">

              {/* Post 1: Gallery Format */}
              <article className="post format-gallery post_format-post-format-gallery">
                <div className="inner">
                  <h2 className="entry-title">
                    <a href="https://leslieloic.com/" title="loic & Leslie mariage">Cérémonie Laïque</a>
                  </h2>
                  <ul className="meta top">
                    <li className="time">
                      <a href="https://leslieloic.com/" title="loic & Leslie mariage">
                        <time className="post-date updated" dateTime="2025-06-14">16h-17h</time>
                      </a>
                    </li>
                  </ul>
                </div>
              </article>

              {/* Post 2: Link Format */}
              <article className="post format-link has-post-thumbnail post_format-post-format-link">
                <div className="inner">
                  <h2 className="entry-title">
                    <a href="https://leslieloic.com/" title="loic & Leslie mariage">Cocktail & Atelier culinaire</a>
                  </h2>
                  <ul className="meta top">
                    <li className="time">
                      <a href="https://leslieloic.com/" title="loic & Leslie mariage">
                        <time className="post-date updated" dateTime="2014-11-06">17h-21h</time>
                      </a>
                    </li>
                  </ul>
                </div>
              </article>

              {/* Post 2: Link Format */}
              <article className="post format-link has-post-thumbnail post_format-post-format-link">
                <div className="inner">
                  <h2 className="entry-title">
                    <a href="https://leslieloic.com/" title="loic & Leslie mariage">Repas Assis</a>
                  </h2>
                  <ul className="meta top">
                    <li className="time">
                      <a href="https://leslieloic.com/" title="loic & Leslie mariage">
                        <time className="post-date updated" dateTime="2014-11-06">21h-22h30</time>
                      </a>
                    </li>
                  </ul>
                </div>
              </article>

              {/* Post 2: Link Format */}
              <article className="post format-link has-post-thumbnail post_format-post-format-link">
                <div className="inner">
                  <h2 className="entry-title">
                    <a href="https://leslieloic.com/" title="loic & Leslie mariage">Soirée</a>
                  </h2>
                  <ul className="meta top">
                    <li className="time">
                      <a href="https://leslieloic.com/" title="loic & Leslie mariage">
                        <time className="post-date updated" dateTime="2014-11-06">de 22h30 jusqu'au bout de la nuit</time>
                      </a>
                    </li>
                  </ul>
                </div>
              </article>

              {/* Additional posts can follow the same structure */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
