import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className='header'>
      <div className="header-wrap">
        <div className="logo plain logo-left">
          <div className="site-title">
            <Link to="/" title="Go to Home">Leslieloic.com</Link>
          </div>
        </div>
        <nav id="nav" role="navigation">
          <div className="table">
            <div className="table-cell">
              <ul id="menu-menu-1">
                <li className="menu-item"><Link to="/">Accueil</Link></li>
                <li className="menu-item"><Link to="/map">Localisation</Link></li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
